<script>
import { indicadorEstoque } from "@/components/defaults/indicadorestoque";
// import Multiselect from "vue-multiselect";

export default {
  props: { currentEmpresa: { type: Object } },
  components: {
    // Multiselect,
  },
  data() {
    return {
      tratamento:{},
      localEstoque: {
        id: -1,
        descricao: null,
        status: true,
        empresa_id: null,
        ind_est: 0,
      },
      listIndicadores: [],
      loader: {
        get: false,
      },

      orientacoes: [
        {
          id: 1,
          nome: "Entrdas e Saídas",
        },
        {
          id: 2,
          nome: "Só entradas",
        },
        {
          id: 3,
          nome: "Só Saídas",
        },
      ],
      tipos: [
        {
          id: 1,
          nome: "Analitica",
        },
        {
          id: 2,
          nome: "Sintetica",
        },
      ],
      select_tipo: null,
      select_orientacao: null,
      planos_contas: [
        {
          id: 1,
          nome: "plano 01",
        },
        {
          id: 2,
          nome: "plano 02",
        },
      ],
      show_multiselect: false,
      select_plano_conta: null,
      plano: {
        woner_id: null,
        descricao: null,
        natureza: null,
        codigo_reduzido: null,
        classificacao: null,
      },
    };
  },
  mounted() {
    this.$emit("newTitle", "Incluir Tratamento");
    this.localEstoque.empresa_id = this.currentEmpresa.id;
    this.listIndicadores = indicadorEstoque;
  },
  methods: {
    abr_multiselect(valor) {
      console.log("entrar");
      if (valor == 1) {
        console.log(valor);
        this.show_multiselect = true;
      } else {
        console.log("esntrou no elsse" + valor);
        this.show_multiselect = false;
      }
    },
    setPost() {
     
      this.$emit("doPost", this.tratamento);
    },
    selecionar_plano(event) {
      this.plano.woner_id = event.id;
    },
    selecionar_tipos(event) {
      this.plano.classificacao = event.id;
    },
    seleciona_orientacoes(event) {
      this.plano.natureza = event.id;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <Label>{{ "Nome" }}</Label>
          <input v-model="tratamento.name" type="text" class="form-control text-left">
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-secondary" @click.prevent="setPost()">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>